/* ==========================================================================
 *  More text show
 * ======================================================================= */
function fn_components_more_text(){
	if ($('.js-more-text').length){
        $(".js-more-text").bind('click', function () {
	        if ($(this).closest('.js-more-block').hasClass('active')){
	        	$(this).closest('.js-more-block').removeClass('active');
	        	if ($(window).width() > 768) var height = 120;
	        	else var height = 100;
	        	
	        	$('html, body').stop().animate({
                    scrollTop: $(this).closest('.js-more-block').offset().top - height
                });
	        } else {
	        	$('.js-more-block.active').removeClass('active');
	        	$(this).closest('.js-more-block').addClass('active');

	        }
	    });
	}
}