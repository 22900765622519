//====================================================
//  Initialization Accordion
//====================================================

var galleryInst;
function fn_components_gallery(){
	if ($('.js-gallery-block').length){
		galleryInst = $('.js-gallery-block').lightGallery({
		    selector: '.js-gallery-item'
		});
	}
	
}