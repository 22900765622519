//====================================================
//  Initialization scroll
//====================================================
function fn_components_side_navigation(){
	if ($('.js-menu-navigation').length){
		checkScroll();
	}
}

var	scrolling = false;
var contentSections = $('.section-scroll'),
	verticalNavigation = $('.js-menu-navigation'),
	navigationItems = verticalNavigation.find('a');

function checkScroll() {
	if( !scrolling ) {
		scrolling = true;
		(!window.requestAnimationFrame) ? setTimeout(updateSections, 300) : window.requestAnimationFrame(updateSections);
	}
}

function updateSections() {
	var halfWindowHeight = $(window).height()/2,
		scrollTop = $(window).scrollTop();
	contentSections.each(function(){
		var section = $(this),
			sectionId = section.attr('id');
			var navigationItem = navigationItems.filter('[href^="#'+ sectionId +'"]');
		( (section.offset().top - halfWindowHeight < scrollTop ) && ( section.offset().top + section.innerHeight() - halfWindowHeight > scrollTop) )
			? navigationItem.addClass('active')
			: navigationItem.removeClass('active');
	});
	scrolling = false;
}



function fn_components_nav_scroll(){
	if ($('.header-scroll').length){
	    var scroll = $(window).scrollTop();
	    var scrolldescirs = $('#section-top').offset().top - 80;

	    if (scroll > 80){
	        $('header').addClass('header-scrolling');
	    } else {
	        $('header').removeClass('header-scrolling');
	    }
 
	    if ($(window).width() > 768 && scroll > scrolldescirs){
	        $('header').addClass('header-scroll-active');
	    } else {
	        $('header').removeClass('header-scroll-active');
	    }
	}
}