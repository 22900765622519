//====================================================
//  Initialization Slider
//====================================================
function fn_components_slider(){
	// fn_speakers_slider();
	// fn_price_slider();
	fn_classic_slider();
}


function fn_classic_slider(){
	if ($('.js-slider-classic').length) {
		$('.js-slider-classic').slick({
			infinite: true,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 2500,
			cssEase: 'linear',
			speed: 500,
			variableWidth: false,
			mobileFirst: true
		});
	}
}



function fn_speakers_slider(){
	if ($('.js-slider-speakers').length) {
		$('.js-slider-speakers').slick({
			infinite: true,
			dots: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 2500,
			cssEase: 'linear',
			speed: 500,
			nextArrow: '<span class="slick-arrows slick-next"><i class="icon icon-chevron-thin-right"></i></span>',
			prevArrow: '<span class="slick-arrows slick-prev"><i class="icon icon-chevron-thin-left"></i></span>',
			//fade: true,
			variableWidth: false,
			mobileFirst: true,
			responsive: [
				{
				  breakpoint: 1220,
				  settings: {
					slidesToShow: 4,
					slidesToScroll: 2,
				  }
				},
				{
				  breakpoint: 990,
				  settings: {
					slidesToShow: 4,
					slidesToScroll: 2,
				  }
				},
				{
				  breakpoint: 550,
				  settings: {
					slidesToShow: 3,
					slidesToScroll: 2,
				  }
				},
				{
				  breakpoint: 250,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				  }
				}
			]
		});
	}
}


function fn_price_slider(){
	if ($('.js-slider-price').length) {
		$('.js-slider-price').slick({
			infinite: true,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			autoplay: false,
			autoplaySpeed: 2500,
			cssEase: 'linear',
			speed: 500,
			variableWidth: false,
			mobileFirst: true
		});
	}
}
