// --- LAYOUT, COMPONENTS, PARTS --- //
$(function(){
	// ---  LAYOUT --- //
	//fn_layout_main();

	// ---  COMPONENTS --- //
	// fn_components_ajaxLoad();
	// fn_components_gallery();
	fn_components_fullscreen_navigation();
	fn_components_slider();
	fn_components_wow();
	fn_components_side_navigation();
	fn_components_nav_scroll();
	fn_components_more_text();
});


// --- MISC --- //
$(function () {
	nl_fn_scroll_to();
});

$(document).scroll(function () {
	fn_components_nav_scroll();
	fn_components_side_navigation();
});

$(window).resize(function(){
	fn_checkNavigationTrigger();
});


$(window).bind('load', function () {
	fn_parts_dots();
	// if ((/msie|trident/i).test(navigator.userAgent)) {
	//  	$("html").addClass("ie");
	// }
	// if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
	// 	$("html").addClass("ie");
	// }
});

