//====================================================
//  Scroll to 
//====================================================
function nl_fn_scroll_to(){
    if( $('.scroll-trigger').length ){
        $('.scroll-trigger').bind('click', function () {
            var inst = $(this);
            var target = $(this).attr('data-scroll');
            if (target.length && !inst.hasClass('active')) {
                $('html, body').stop().animate({
                    scrollTop: $('#' + target + '').offset().top - 60
                }, function() {
                    if ($(window).width() < 768){
                        $('.navigation').removeClass('nav-active');
                    }
                });
                // return false;
            }
        });
    }
}