/* ==========================================================================
 *  Ajax load more post to blog grid
 * ======================================================================= */


function fn_components_ajaxLoad(){
	if ($('.js-load-more-content').length){
        $('.js-load-more-content').bind('click', function(){

            var thisGrid = $(this).closest('.js-load-more-block');

            $.ajax({
                url: 'public/js/data/gallery.html',
                data: 'html',
                beforeSend:function(){
                    thisGrid.find('.js-load-more').addClass('is-disabled');
                    thisGrid.find('.js-load-more .loading-post').addClass('is-active');
                },
                success:function(data){
                    setTimeout(function(){ 
                        thisGrid.find('.js-load-more-grid').append(data);
                        thisGrid.find('.js-load-more').removeClass('is-disabled')
                        thisGrid.addClass('is-last');
                        thisGrid.find('.js-load-more .loading-post').removeClass('is-active');

                        // reinit gallery
                        galleryInst.data('lightGallery').destroy(true);
                        fn_components_gallery();
                    }, 2000);
                },
                error:function(){
                    console.log("error");
                }
            });

        });
    }
}
